@import "src/themes/daikiri/styles/index.scss";
.link {
  appearance: none !important;
  -webkit-appearance: none !important;
  text-decoration: none !important;
  padding: 0;
  background-color: transparent;
  border: 1px solid transparent;
  color: getvar($colors, "primary", "500");
  min-width: auto;

  &:hover {
    background-color: transparent;
    color: getvar($colors, "primary", "700");
  }
  &:focus {
    background-color: transparent;
    color: getvar($colors, "primary", "600");
  }
}

.button-link {
  &.bold-text {
    color: getvar($button, "primary", "color") !important;
    color: inherit;
    font-weight: 700 !important;
    font-size: 20px;
    @include sm {
      font-size: space(1);
    }
    @include md {
      font-size: 20px;
    }
  }

  &.button--ghost {
    appearance: none !important;
    -webkit-appearance: none !important;
    padding: 0;
    background-color: getvar($button, "ghost", "bg-color") !important;
    border: 1px solid transparent;
    color: getvar($button, "ghost", "color") !important;
    min-width: auto;

    &:hover {
      background-color: getvar($button, "ghost", "hover-bg-color");
      color: getvar($button, "ghost", "hover-color");
    }
    &:focus {
      background-color: getvar($button, "ghost", "focus-bg-color");
      color: getvar($button, "ghost", "focus-color");
    }
  }
}
