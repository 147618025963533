@import "src/themes/daikiri/styles/index.scss";
.dashboard__content {
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;

  padding: space(1.5) space(2) space(1);

  @include lg {
    padding-bottom: space(2);
  }
}
