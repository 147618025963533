@import "src/themes/daikiri/styles/index.scss";
.button {
  @extend .u-borderRadius-small;
  background-color: getvar($button, "primary", "bg-color");
  border: 1px solid transparent;
  color: getvar($button, "primary", "color");
  cursor: pointer;
  display: inline-block;
  padding: space(1) space(2);
  min-width: space(11.75);
  text-align: center;
  appearance: none !important;
  -webkit-appearance: none !important;
  text-decoration-line: underline !important;
  -webkit-text-decoration-line: underline !important;
  text-decoration-color: rgba(0, 0, 0, 0) !important;
  -webkit-text-decoration-color: rgba(0, 0, 0, 0) !important;
  transition: all ease-in-out 0.2s;
  box-sizing: border-box;
  position: relative;
  letter-spacing: -0.2px;

  &--chip {
    position: absolute;
    top: 8px;
    right: 8px;

    &.Chip {
      min-width: 36px;
      font-size: 10px;
    }

    @include lg {
      min-width: 50px;
      font-size: 10px;
      top: 16px;
      right: 16px;
    }
  }

  &.active {
    .button__text {
      font-weight: 700;
    }
  }
  &__text {
    font-weight: 700;
    font-size: 20px;
  }

  &:hover {
    background-color: getvar($button, "primary", "hove-bg-color");
    color: getvar($button, "primary", "hover-color");
    text-decoration: underline 0.1em rgba(0, 0, 0, 0);
    text-decoration-color: rgba(0, 0, 0, 0) !important;
    -webkit-text-decoration-color: rgba(0, 0, 0, 0) !important;
  }

  &:focus {
    background-color: getvar($button, "primary", "focus-bg-color");
    color: getvar($button, "primary", "focus-color");
    text-decoration: underline 0.1em rgba(0, 0, 0, 0);
    text-decoration-color: rgba(0, 0, 0, 0) !important;
    -webkit-text-decoration-color: rgba(0, 0, 0, 0) !important;
    outline: none;
  }
  &:disabled {
    background-color: getvar($button, "primary", "diabled-bg-color");
    color: getvar($button, "primary", "disabled-color");
    text-decoration: underline 0.1em rgba(0, 0, 0, 0);
    text-decoration-color: rgba(0, 0, 0, 0) !important;
    -webkit-text-decoration-color: rgba(0, 0, 0, 0) !important;
  }

  &--auth {
    margin-bottom: 24px !important;
    margin-left: 0px !important;
    width: 100%;

    @include sm {
      margin-left: auto !important;
      margin-top: 0 !important;
      max-width: 325px !important;
    }
  }

  &--secondary {
    background-color: getvar($button, "secondary", "bg-color");
    // border: 1px solid getvar($colors, "primary", "500");
    color: getvar($button, "secondary", "color");

    &:hover {
      background-color: getvar($button, "secondary", "hover-bg-color");
      // border: 1px solid getvar($colors, "primary", "700");
      color: getvar($button, "secondary", "hover-color");
      text-decoration: underline 0.1em rgba(0, 0, 0, 0);
      text-decoration-color: rgba(0, 0, 0, 0) !important;
      -webkit-text-decoration-color: rgba(0, 0, 0, 0) !important;
    }
    &:focus {
      background-color: getvar($button, "secondary", "focus-bg-color");
      color: getvar($button, "secondary", "focus-color");
      text-decoration: underline 0.1em rgba(0, 0, 0, 0);
      text-decoration-color: rgba(0, 0, 0, 0) !important;
      -webkit-text-decoration-color: rgba(0, 0, 0, 0) !important;
    }
    &:disabled {
      background-color: getvar($button, "secondary", "disabled-bg-color");
      color: getvar($button, "secondary", "disabled-color");
      text-decoration: underline 0.1em rgba(0, 0, 0, 0);
      text-decoration-color: rgba(0, 0, 0, 0) !important;
      -webkit-text-decoration-color: rgba(0, 0, 0, 0) !important;
    }
  }

  &--extralarge {
    font-size: space(1.25);
    font-weight: 700;
    line-height: space(1.5);
    letter-spacing: -0.2px;

    height: 54px;
    padding: space(0.75) space(1) !important;
    align-items: center;
    min-width: auto;

    .button__text {
      font-size: space(1.25);
      font-weight: 700;
      line-height: space(1.5);
      letter-spacing: -0.2px;
    }

    svg {
      height: 24px !important;
      width: 24px !important;
    }
  }

  &--large {
    font-size: space(1);
    font-weight: 600;
    line-height: space(1.5);
    letter-spacing: -0.2px;

    height: 48px;
    padding: space(0.75) space(1) !important;
    align-items: center;
    min-width: auto;

    .button__text {
      font-size: space(1);
      font-weight: 600;
      line-height: space(1.5);
      letter-spacing: -0.2px;
    }

    svg {
      height: 24px !important;
      width: 24px !important;
    }
  }

  &--medium {
    font-size: space(0.875);
    font-weight: 600;
    line-height: space(1.125);
    letter-spacing: -0.2px;

    height: 40px;
    padding: space(0.625);
    align-items: center;
    min-width: auto;

    .button__text {
      font-size: space(0.875);
      font-weight: 600;
      line-height: space(1.125);
      letter-spacing: -0.2px;
    }

    svg {
      height: 24px !important;
      width: 24px !important;
    }

    div {
      .spinner {
        width: 15px !important;
        height: 15px !important;
      }
    }
  }

  &--small {
    font-size: space(0.75);
    font-weight: 600;
    line-height: space(1);
    letter-spacing: -0.2px;
    height: 32px;
    padding: space(0.625);
    align-items: center;
    min-width: auto;

    .button__text {
      font-size: space(0.75);
      font-weight: 600;
      line-height: space(1);
      letter-spacing: -0.2px;
    }

    svg {
      height: 18px !important;
      width: 18px !important;
    }

    div {
      margin-right: 0 !important;

      .spinner {
        width: 15px !important;
        height: 15px !important;
      }
    }
  }

  &--full {
    width: 100%;
    box-sizing: border-box !important;
  }

  &--square {
    padding: space(0.5);
    min-width: auto;
  }

  &--ghost {
    appearance: none !important;
    -webkit-appearance: none !important;
    padding: 0;
    background-color: getvar($button, "ghost", "bg-color");
    border: 1px solid transparent;
    color: getvar($button, "ghost", "color");
    min-width: auto;

    &:hover {
      background-color: getvar($button, "ghost", "hover-bg-color");
      color: getvar($button, "ghost", "hover-color");
    }
    &:focus {
      background-color: getvar($button, "ghost", "focus-bg-color");
      color: getvar($button, "ghost", "focus-color");
    }
  }

  &--icon {
    display: inline-flex !important;
    align-items: center;
    justify-content: center;

    .button__text {
      margin-right: space(0.5);
    }

    svg {
      height: space(1.5);
      width: space(1.5);
    }
  }

  &--tertiary {
    background-color: getvar($button, "tertiary", "bg-color");
    border: 1px solid getvar($colors, "primary", "500");
    color: getvar($button, "tertiary", "color");

    &:hover {
      background-color: getvar($button, "tertiary", "hover-bg-color") !important;
      border: 1px solid getvar($colors, "primary", "100") !important;
      color: getvar($button, "tertiary", "hover-color") !important;
    }
    &:focus {
      background-color: getvar($button, "tertiary", "focus-bg-color");
      border: 1px solid getvar($colors, "primary", "600") !important;
      color: getvar($button, "tertiary", "focus-color") !important;
    }
    &:disabled {
      background-color: getvar($button, "tertiary", "disabled-bg-color");
      border: 1px solid getvar($colors, "primary", "200");
      color: getvar($button, "tertiary", "disabled-color");
    }
  }

  &--iconLeft {
    flex-direction: row-reverse;

    .button__text {
      margin-left: space(0.25);
      margin-right: 0;
    }
  }

  &--iconTop {
    flex-direction: column-reverse;

    .button__text {
      margin: 0;
      font-size: 10px !important;
    }
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;

    &--valid {
      pointer-events: all;
      cursor: pointer;
    }
  }

  &--loading {
    div {
      height: 20px;
      margin-right: 3px;

      .spinner {
        animation: rotate 2s linear infinite;
        width: 20px;
        height: 20px;

        & .path {
          stroke: #93bfec;
          stroke-linecap: round;
          animation: dash 1.5s ease-in-out infinite;
        }
      }
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }

      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }

      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
  }

  &--bold {
    background-color: transparent;
    border: 1px solid getvar($colors, "neutral", "300");
    color: getvar($colors, "neutral", "900");
    padding: space(0.5) space(0.75);
    min-width: auto;
    @extend .u-shadow-small;

    @include sm {
      padding: space(0.5) space(1);
    }

    @include md {
      padding: space(0.75) space(1.25);
    }

    .button__text {
      margin-left: space();
    }

    .button__icon div {
      align-items: center;
      display: flex;
      position: relative;

      &:before {
        content: "";
        border-radius: 100%;
        background-color: getvar($colors, "neutral", "100");
        height: calc(100% + 12px);
        position: absolute;
        left: -6px;
        top: -6px;
        width: calc(100% + 12px);
      }

      svg {
        position: relative;
      }
    }

    &:hover {
      background-color: getvar($colors, "neutral", "50");
      border: 1px solid getvar($colors, "primary", "500");
      color: getvar($colors, "neutral", "900");
      text-decoration: underline 0.1em rgba(0, 0, 0, 0);
      text-decoration-color: rgba(0, 0, 0, 0) !important;
      -webkit-text-decoration-color: rgba(0, 0, 0, 0) !important;
      @extend .u-shadow-medium;

      &:before {
        background-color: getvar($colors, "neutral", "900");
      }
    }

    &.button--active,
    &.active {
      border: 1px solid getvar($colors, "primary", "500");

      .button__icon div {
        &:before {
          background-color: getvar($colors, "primary", "500");
        }
      }
    }
  }

  &--bold.button--description {
    justify-content: flex-end !important;

    .button__icon div {
      height: space(1.5);
      width: space(1.5);
      margin-bottom: space(0.5);

      img {
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }

    @include md {
      flex-direction: column-reverse;
      justify-content: center !important;
      padding: space(1);

      .button__description {
        margin-left: 0;
        text-align: center;

        &__subtitle {
          font-size: space(1);
          width: 96%;
          text-align: center;
        }
      }

      .button__icon div {
        height: space(4.5);
        width: space(4.5);
      }
    }
  }

  &--bold-text {
    .button__text {
      color: inherit;
      font-weight: 700 !important;
      font-size: 20px;
      @include sm {
        font-size: space(1);
      }
      @include md {
        font-size: 20px;
      }
    }
  }

  &--step {
    border-radius: space(10);
    color: getvar($colors, "primary", "900");
    height: space(2.5);
    padding: 0;
    display: flex;
    position: relative;
    justify-content: center !important;
    align-items: center;
    min-width: 0;
    width: auto;
    width: space(2.25);
    height: space(2.25);
    border: 0 !important;
    box-shadow: none !important;
    cursor: initial;

    @include lg {
      min-width: space(11.75);
      width: auto;
      background-color: getvar($colors, "neutral", "50");
    }

    span {
      position: absolute;
      bottom: space(-1.5);
      min-width: max-content;
      font-size: space(0.75) !important;
      margin-left: space(0) !important;
      margin-right: space(0) !important;
      color: getvar($colors, "neutral", "800");
      font-weight: 500 !important;

      @include lg {
        position: initial;
        color: getvar($colors, "primary", "500");
        font-size: space(1) !important;
      }
    }

    &--icon {
      background-color: getvar($colors, "primary", "500");
      color: getvar($colors, "primary", "50");
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: space(0.25);
      width: space(1.5);
      height: space(1.5);

      @include lg {
        margin: space(0.5);
      }
    }

    &--done {
      color: getvar($colors, "neutral", "800");

      span {
        @include lg {
          color: getvar($colors, "neutral", "800") !important;
        }
      }

      & .button--step--icon {
        color: #00af63;
        border: 1px solid #00af63;
        background-color: getvar($colors, "neutral", "50");
      }
    }
  }

  &--step.button--disabled {
    color: getvar($colors, "neutral", "500");
    border: 1px solid getvar($colors, "neutral", "50");
    opacity: 1;

    span {
      color: getvar($colors, "neutral", "500");
    }

    & .button--step--icon {
      background-color: getvar($colors, "neutral", "100");
      border: 1px solid getvar($colors, "neutral", "500");
      color: getvar($colors, "neutral", "500");
    }
  }

  &__description {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: space(0.75);

    @include sm {
      margin-left: space(1);
    }

    &__title {
      color: getvar($colors, "neutral", "800");
      font-size: space(0.75);
      font-weight: 700;
      line-height: space(1);
      max-width: space(5.8);
      @extend .u-spacing-1;

      @include sm {
        max-width: none;
        font-size: space(1);
      }
    }

    &__subtitle {
      color: getvar($colors, "neutral", "400");
      font-size: space(0.75);
      letter-spacing: -0.4px;
      line-height: space(1);
    }
  }

  &--helper-button {
    width: space(4);
    min-width: inherit !important;

    &--hidden {
      display: none !important;

      @include lg {
        display: block !important;
        visibility: hidden;
      }
    }
  }

  &--block {
    background-color: transparent;
    border-left: 4px solid transparent;
    text-align: left;
    padding: space();
    opacity: getvar($button, "block", "opacity");
    color: getvar($button, "block", "color");
    &:focus,
    &:hover,
    &.active {
      color: getvar($button, "block", "hover-color");
      opacity: 0.8;
      border-left: 4px solid getvar($colors, "primary", "500");
      background-color: getvar($button, "block", "hover-bg-color") !important;
    }

    &.active {
      opacity: 1;

      .button__text {
        font-weight: 600;
      }
    }

    svg {
      margin-right: space(0.75);
    }
  }

  &--rounded {
    @extend .u-rounded-button;
  }
}

button.button {
  font: inherit;

  &--small {
    font-size: space(0.875);
  }
}

.button--disabled.button--loading.button--square {
  opacity: 1 !important;
}

a.button--medium,
a.button--large {
  /* a text decoration adds weird bottom space */
  padding-top: 11px !important;
}
